import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { selectAllEstates, selectAllPayments } from '../guard';

// TODO: we should create dedicated slices for estates context content to not mix with users context
// estates-orders, estates-payments, estates-subscriptions

export const selectAllPaymentsIncludeEstate = createDraftSafeSelector(
  selectAllPayments,
  selectAllEstates,
  (allPayments, allEstates) => {
    return allPayments.map(p => {
      const estate = allEstates.find(e => e.id === p.estateId);
      return { ...p, estate };
    });
  }
);

export const selectAllPaymentsByEstateId = estateId =>
  createDraftSafeSelector(selectAllPayments, payments => {
    return payments.filter(p => p.estateId === estateId);
  });
