import { orderAddressesAdapter } from './slice';

const selectSelf = state => state.shop.orderAddresses;

export const {
  selectById: selectOrderAddressById,
  selectIds: selectOrderAddressIds,
  selectEntities: selectOrderAddressEntities,
  selectAll: selectAllOrderAddresses,
  selectTotal: selectTotalOrderAddresses,
} = orderAddressesAdapter.getSelectors(selectSelf);
