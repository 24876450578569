import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { fetchAllReceivedInvitations, respondReceivedInvitation } from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const receivedInvitationsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const receivedInvitations = createSlice({
  name: 'receivedInvitations',
  initialState,
  reducers: {
    resetReceivedInvitations: () => initialState,
    handleAddOneReceivedInvitation: (state, { payload: { receivedInvitation } }) => {
      receivedInvitationsAdapter.addOne(state, receivedInvitation);
    },
    handleRemoveManyReceivedInvitations: (state, { payload: { receivedInvitation } }) => {
      receivedInvitationsAdapter.removeMany(state, receivedInvitation);
    },
  },
  extraReducers: {
    [fetchAllReceivedInvitations.fulfilled]: (state, action) => {
      if (action.payload.entities.receivedInvitations) {
        receivedInvitationsAdapter.setAll(state, action.payload.entities.receivedInvitations);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllReceivedInvitations.pending]: (state, action) => {
      // console.log('fetchAllReceivedInvitations pending');
      state.loading = true;
    },
    [fetchAllReceivedInvitations.rejected]: (state, action) => {
      // console.log('fetchAllReceivedInvitations rejected');
      state.error = action.error;
      state.loading = false;
    },
    [respondReceivedInvitation.fulfilled]: (state, action) => {
      receivedInvitationsAdapter.removeOne(state, action.payload.id);
      state.error = false;
      state.loading = false;
    },
    [respondReceivedInvitation.pending]: (state, action) => {
      state.loading = true;
    },
    [respondReceivedInvitation.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const {
  resetReceivedInvitations,
  handleAddOneReceivedInvitation,
  handleRemoveManyReceivedInvitations,
} = receivedInvitations.actions;
export const receivedInvitationsReducer = receivedInvitations.reducer;
