import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { fetchAllMondialRelays, fetchMondialRelaysByPosition } from './thunks';

// Define redux-toolkit entity adapter
export const mondialRelaysAdapter = createEntityAdapter({
  // sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

const mondialRelays = createSlice({
  name: 'mondialRelays',
  initialState,
  reducers: {
    resetMondialRelays: () => initialState,
  },
  extraReducers: {
    [fetchAllMondialRelays.fulfilled]: (state, action) => {
      if (action.payload.entities.mondialRelays) {
        mondialRelaysAdapter.setAll(state, action.payload.entities.mondialRelays);
      } else {
        mondialRelaysAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllMondialRelays.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllMondialRelays.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchMondialRelaysByPosition.fulfilled]: (state, action) => {
      if (action.payload.entities.mondialRelays) {
        mondialRelaysAdapter.setAll(state, action.payload.entities.mondialRelays);
      } else {
        mondialRelaysAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchMondialRelaysByPosition.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchMondialRelaysByPosition.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { resetMondialRelays } = mondialRelays.actions;
export const mondialRelaysReducer = mondialRelays.reducer;
