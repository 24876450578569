import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  fetchAllEstatesSubscriptions,
  fetchAllSubscriptions,
  fetchSubscriptionByEstateId,
} from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const subscriptionsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const subscriptions = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    resetSubscriptions: () => initialState,
  },
  extraReducers: {
    [fetchAllSubscriptions.fulfilled]: (state, action) => {
      if (action.payload.entities.subscriptions) {
        subscriptionsAdapter.setAll(state, action.payload.entities.subscriptions);
      } else {
        subscriptionsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllSubscriptions.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllSubscriptions.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchAllEstatesSubscriptions.fulfilled]: (state, action) => {
      if (action.payload.entities.subscriptions) {
        subscriptionsAdapter.setAll(state, action.payload.entities.subscriptions);
      } else {
        subscriptionsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllEstatesSubscriptions.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllEstatesSubscriptions.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchSubscriptionByEstateId.fulfilled]: (state, action) => {
      if (action.payload.entities.subscriptions) {
        subscriptionsAdapter.upsertMany(state, action.payload.entities.subscriptions);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchSubscriptionByEstateId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchSubscriptionByEstateId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    /* [createSubscriptionOrPaymentUrl.fulfilled]: (state, action) => {
      if (typeof action.payload !== 'string') {
        subscriptionsAdapter.upsertOne(state, action.payload);
      }
      state.error = false;
      state.loading = false;
    },
    [createSubscriptionOrPaymentUrl.pending]: (state, action) => {
      state.loading = true;
    },
    [createSubscriptionOrPaymentUrl.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    }, */
  },
});

export const { resetSubscriptions } = subscriptions.actions;
export const subscriptionsReducer = subscriptions.reducer;
