import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { paymentsAdapter } from './slice';

const selectSelf = state => state.guard.payments;

export const {
  selectById: selectPaymentById,
  selectIds: selectPaymentIds,
  selectEntities: selectPaymentEntities,
  selectAll: selectAllPayments,
  selectTotal: selectTotalPayments,
} = paymentsAdapter.getSelectors(selectSelf);

export const selectPaymentByTaekiaId = taekiaId =>
  createDraftSafeSelector(selectAllPayments, payments => {
    return payments.find(p => p.taekiaId === taekiaId);
  });
