import { AUTH_API_URL } from 'app/config';
import axios from 'axios';

const pureAuthApi = axios.create({ baseURL: AUTH_API_URL });

const parseError = err => {
  // Parse the backend response error
  const error = err.response?.data.error;
  // Reject with the formatted error
  return Promise.reject(error);
};

// response parsing interceptor
pureAuthApi.interceptors.response.use(response => response.data, parseError);

export { pureAuthApi };
