export const isBrowser = typeof window !== 'undefined';

export const getBrowserInfo = () => {
  const { userAgent } = navigator;
  const parsedUserAgent = userAgent.match(
    /(MSIE|Trident|(?!Gecko.+)Firefox|(?!AppleWebKit.+Chrome.+)Safari(?!.+Edge)|(?!AppleWebKit.+)Chrome(?!.+Edge)|(?!AppleWebKit.+Chrome.+Safari.+)Edge|AppleWebKit(?!.+Chrome|.+Safari)|Gecko(?!.+Firefox))(?: |\/)([\d.apre]+)/
  );
  const name = parsedUserAgent[1].toLowerCase();
  const version = `v${parsedUserAgent[2]}`;
  return { name, version };
};
