import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { guardApi } from '../../../connectivity';

// Define normalizr entity schemas
const receivedInvitationEntity = new schema.Entity('receivedInvitations');

// Thunks definitions
export const fetchAllReceivedInvitations = createAsyncThunk(
  'receivedInvitations/fetchAllReceivedInvitations',
  async ({ userId }, { getState }) => {
    const data = await guardApi.get(`/users/${userId}/invitations-received`);
    const normalized = normalize(data, [receivedInvitationEntity]);
    return normalized;
  }
);

export const respondReceivedInvitation = createAsyncThunk(
  'receivedInvitations/respondReceivedInvitation',
  async ({ userId, invitation, response }, { getState, dispatch }) => {
    // `reponse` is `accept` or `decline`
    await guardApi.get(
      `/users/${userId}/invitations-received/${invitation.id}?response=${response}`
    );
    return invitation;
  }
);
