import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { selectAllEstates, selectAllOrders } from '../guard';

// TODO: we should create dedicated slices for estates context content to not mix with users context
// estates-orders, estates-payments, estates-subscriptions

export const selectAllOrdersIncludeEstate = createDraftSafeSelector(
  selectAllOrders,
  selectAllEstates,
  (allOrders, allEstates) => {
    return allOrders
      .filter(ord => ord.status !== 'completed')
      .map(ord => {
        const estate = allEstates.find(est => est.id === ord.estateId);
        return { ...ord, estate };
      });
  }
);
