import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { fetchAllShopSections } from './thunks';

// Define redux-toolkit entity adapter
export const shopSectionsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.title.localeCompare(b.title), // sort by ascending `title`
});

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

const shopSections = createSlice({
  name: 'shopSections',
  initialState,
  reducers: {
    resetShopSections: () => initialState,
  },
  extraReducers: {
    [fetchAllShopSections.fulfilled]: (state, action) => {
      if (action.payload.entities.shopSections) {
        shopSectionsAdapter.setAll(state, action.payload.entities.shopSections);
      } else {
        shopSectionsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllShopSections.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllShopSections.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { resetShopSections } = shopSections.actions;
export const shopSectionsReducer = shopSections.reducer;
