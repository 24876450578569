import { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { enUS } from './en-US';
import { frFR } from './fr-FR';

const resources = {
  'en-US': { translation: enUS },
  'fr-FR': { translation: frFR },
};

const i18n = use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: /* 'en-US' */ 'fr-FR', //NOTE: temp force french
    supportedLngs: [/* 'en-US', */ 'fr-FR'], //NOTE: temp force french
    interpolation: {
      escapeValue: false,
    },
  });

export { i18n };
