import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { fetchAllShopItems, fetchShopItemBySlug } from './thunks';

// Define redux-toolkit entity adapter
export const shopItemsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.unitPrice.value > b.unitPrice.value, // sort by ascending `unitPrice.value`
});

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

const shopItems = createSlice({
  name: 'shopItems',
  initialState,
  reducers: {
    resetShopItems: () => initialState,
  },
  extraReducers: {
    [fetchAllShopItems.fulfilled]: (state, action) => {
      if (action.payload.entities.shopItems) {
        shopItemsAdapter.setAll(state, action.payload.entities.shopItems);
      } else {
        shopItemsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllShopItems.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllShopItems.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchShopItemBySlug.fulfilled]: (state, action) => {
      if (action.payload.entities.shopItems) {
        shopItemsAdapter.upsertMany(state, action.payload.entities.shopItems);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchShopItemBySlug.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchShopItemBySlug.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { resetShopItems } = shopItems.actions;
export const shopItemsReducer = shopItems.reducer;
