import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { shopApi } from '../../../connectivity';

// Define normalizr entity schemas
const mondialRelayEntity = new schema.Entity('mondialRelays');

// Thunks definitions
export const fetchAllMondialRelays = createAsyncThunk(
  'mondialRelays/fetchAllMondialRelays',
  async ({ countryCode, postalCode, nbResults = 10 }, { getState }) => {
    let data = await shopApi.get(`/mondial-relays/${countryCode}/${postalCode}/${nbResults}`);
    data = data.map(r => ({
      value: r,
      label: r.address.formatted,
    }));
    const normalized = normalize(data, [mondialRelayEntity]);
    return normalized;
  }
);

export const fetchMondialRelaysByPosition = createAsyncThunk(
  'mondialRelays/fetchMondialRelaysByPosition',
  async ({ countryCode, lat, lng, nbResults = 10 }, { getState }) => {
    let data = await shopApi.get(
      `/mondial-relays/position/${countryCode}/${lat}/${lng}/${nbResults}`
    );
    data = data.map(r => ({
      value: r,
      label: r.address.formatted,
    }));
    const normalized = normalize(data, [mondialRelayEntity]);
    return normalized;
  }
);
