export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getInitials = name => {
  const parts = name ? name.split(/[ -]/) : [];
  let initials = '';
  for (let i = 0; i < parts.length; i += 1) {
    initials += parts[i].charAt(0);
  }
  if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
    initials = initials.replace(/[a-z]+/g, '');
  }
  initials = initials.substr(0, 2).toUpperCase();
  return initials;
};

export const formatPrice = (amount, lng, zeroDecimals = false) => {
  if (!amount || !amount.value || !amount.currency) return;
  if (!lng) lng = 'en-US';
  const price = Number(amount.value);

  return zeroDecimals && price % 1 === 0
    ? Intl.NumberFormat(lng, {
        style: 'currency',
        currency: amount.currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(price)
    : Intl.NumberFormat(lng, {
        style: 'currency',
        currency: amount.currency,
        minimumFractionDigits: 2,
      }).format(price);
};

export const formatInterval = (t, interval) => {
  const intervalList = interval.split(' ');
  const intervalNumber = intervalList[0];
  const intervalType = intervalList[1];

  if (intervalType === 'month' || intervalType === 'months') {
    if (intervalNumber === '1') return t('PERIOD.PER_MONTH');
    else return t('PERIOD.EVERY_MONTH', { interval: intervalNumber });
  } else if (intervalType === 'day' || intervalType === 'days') {
    if (intervalNumber === '1') return t('PERIOD.PER_DAY');
    else return t('PERIOD.EVERY_DAY', { interval: intervalNumber });
  } else {
    return t('PERIOD.ERROR');
  }
};

export const formatPhone = phone =>
  phone.replace(/(\d{2})(\d{1})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5 $6');

export const formatStock = (t, stock) => {
  if (stock > 0) {
    return { stockLabel: t('Shop.STOCK.IN_STOCK'), stockColor: 'success' };
  } else if (stock === 0) {
    return { stockLabel: t('Shop.STOCK.ONGOING_REPLENISHMENT'), stockColor: 'warning' };
  } else if (stock === -1) {
    return { stockLabel: t('Shop.STOCK.OUT_OF_STOCK'), stockColor: 'error' };
  } else {
    return { stockLabel: t('Shop.STOCK.ERROR'), stockColor: 'error' };
  }
};
