import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { shopItemsAdapter } from './slice';

const selectSelf = state => state.shop.shopItems;

export const {
  selectById: selectShopItemById,
  selectIds: selectShopItemIds,
  selectEntities: selectShopItemEntities,
  selectAll: selectAllShopItems,
  selectTotal: selectTotalShopItems,
} = shopItemsAdapter.getSelectors(selectSelf);

export const selectShopItemByUslugAndLanguage = (uslug, language) =>
  createDraftSafeSelector(selectAllShopItems, shopItems => {
    return shopItems.find(i => i.uslug === uslug && i.language === language);
  });
