import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { addOneOrderAddress, fetchAllOrderAddresses } from './thunks';

// Define redux-toolkit entity adapter
export const orderAddressesAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

const orderAddresses = createSlice({
  name: 'orderAddresses',
  initialState,
  reducers: {
    resetOrderAddresses: () => initialState,
  },
  extraReducers: {
    [fetchAllOrderAddresses.fulfilled]: (state, action) => {
      if (action.payload.entities.orderAddresses) {
        orderAddressesAdapter.setAll(state, action.payload.entities.orderAddresses);
      } else {
        orderAddressesAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllOrderAddresses.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllOrderAddresses.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [addOneOrderAddress.fulfilled]: (state, action) => {
      orderAddressesAdapter.addOne(state, action.payload);
      state.error = false;
      state.loading = false;
    },
    [addOneOrderAddress.pending]: (state, action) => {
      state.loading = true;
    },
    [addOneOrderAddress.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { resetOrderAddresses } = orderAddresses.actions;
export const orderAddressesReducer = orderAddresses.reducer;
