import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { guardApi } from '../../../connectivity';

// Define normalizr entity schemas
const estateEntity = new schema.Entity('estates');

// Thunks definitions
export const fetchAllEstates = createAsyncThunk(
  'estates/fetchAllEstates',
  async ({ userId }, { getState }) => {
    const filter = { order: 'name ASC' };
    const data = await guardApi.get(`/users/${userId}/estates`, { params: { filter } });
    const normalized = normalize(data, [estateEntity]);
    // console.log(JSON.stringify(normalized, null, 2));
    return normalized;
  }
);

export const fetchEstateById = createAsyncThunk(
  'estates/fetchEstateById',
  async ({ userId, estateId }, { getState }) => {
    const filter = {
      where: { id: estateId },
      order: 'name ASC',
    };
    const data = await guardApi.get(`/users/${userId}/estates`, {
      params: { filter },
    });
    const normalized = normalize(data, [estateEntity]);
    // console.log(JSON.stringify(normalized, null, 2));
    return normalized;
  }
);

export const addOneEstate = createAsyncThunk(
  'estates/addOneEstate',
  async ({ userId, estate }, { getState, dispatch }) => {
    const data = await guardApi.post(`/users/${userId}/estates`, {
      name: estate.name,
      usage: estate.usage,
      type: estate.type,
      address: estate.address,
      openingsDoors: estate.openingsDoors,
      openingsWindows: estate.openingsWindows,
    });
    return data;
  }
);

export const updateOneEstate = createAsyncThunk(
  'estates/updateOneEstate',
  async ({ userId, estate }, { getState }) => {
    const where = JSON.stringify({ id: estate.id });
    await guardApi.patch(`/users/${userId}/estates?where=${where}`, {
      name: estate.name,
      usage: estate.usage,
      type: estate.type,
      address: estate.address,
      openingsDoors: estate.openingsDoors,
      openingsWindows: estate.openingsWindows,
    });
    return estate;
  }
);

export const switchEstateAlarm = createAsyncThunk(
  'estates/switchEstateAlarm',
  async ({ memberId, estateId, alarmActivated, alarmMode }, { getState }) => {
    await guardApi.patch(
      `/members/${memberId}/estates/${estateId}/alarm-activated/${alarmActivated}?alarm-mode=${alarmMode}`
    );
    return { id: estateId, alarmActivated, alarmMode };
  }
);

export const removeOneEstate = createAsyncThunk(
  'estates/removeOneEstate',
  async ({ userId, estate }, { getState }) => {
    const where = JSON.stringify({ id: estate.id });
    await guardApi.delete(`/users/${userId}/estates?where=${where}`);
    return estate;
  }
);
