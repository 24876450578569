import { useEffect } from 'react';

import { isBrowser } from '../browser';

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOutside = (ref, cb) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        return cb && cb(event);
      }
    };

    // Filter for SSR
    if (isBrowser) {
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
