import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  addOneOrder,
  fetchAllEstatesOrders,
  fetchAllOrders,
  fetchOrderByEstateId,
  fetchOrderByTaekiaId,
} from './thunks';

// Define redux-toolkit entity adapter
export const ordersAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

const orders = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    resetOrders: () => initialState,
  },
  extraReducers: {
    [fetchAllOrders.fulfilled]: (state, action) => {
      if (action.payload.entities.orders) {
        ordersAdapter.setAll(state, action.payload.entities.orders);
      } else {
        ordersAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllOrders.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllOrders.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchAllEstatesOrders.fulfilled]: (state, action) => {
      if (action.payload.entities.orders) {
        ordersAdapter.setAll(state, action.payload.entities.orders);
      } else {
        ordersAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllEstatesOrders.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllEstatesOrders.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchOrderByTaekiaId.fulfilled]: (state, action) => {
      if (action.payload.entities.orders) {
        ordersAdapter.upsertMany(state, action.payload.entities.orders);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchOrderByTaekiaId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchOrderByTaekiaId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchOrderByEstateId.fulfilled]: (state, action) => {
      if (action.payload.entities.orders) {
        ordersAdapter.upsertMany(state, action.payload.entities.orders);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchOrderByEstateId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchOrderByEstateId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [addOneOrder.fulfilled]: (state, action) => {
      ordersAdapter.addOne(state, action.payload);
      state.error = false;
      state.loading = false;
    },
    [addOneOrder.pending]: (state, action) => {
      state.loading = true;
    },
    [addOneOrder.rejected]: (state, action) => {
      state.error = action.payload || action.error;
      state.loading = false;
    },
  },
});

export const { resetOrders } = orders.actions;
export const ordersReducer = orders.reducer;
