import { AUTH_API_URL } from 'app/config';
import axios from 'axios';

import { getTaekiaApiHeaders } from '../../helpers';

const authApi = axios.create({ baseURL: AUTH_API_URL });

const parseError = err => {
  // Parse the backend response error
  const error = err.response?.data.error;
  // Reject with the formatted error
  return Promise.reject(error);
};

// response parsing interceptor
authApi.interceptors.response.use(response => response.data, parseError);

// request parsing interceptor
authApi.interceptors.request.use(
  async config => {
    config.headers = await getTaekiaApiHeaders();
    return config;
  },
  error => Promise.reject(error)
);

export { authApi };
