import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartItems: [],
  itemCount: 0,
  total: { currency: '', value: '' },
  loading: false,
  error: false,
};

const cart = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    resetCart: () => initialState,
    addCartItem: (state, action) => {
      const { shopItem, itemQuantity = 1 } = action.payload;

      let itemFound = false;
      let finalCartItems = state.cartItems;
      let itemCount = state.itemCount;
      let total = state.total;

      // check if the item to add is already in the cart and if so change the quantity
      finalCartItems = finalCartItems.map(cartItem => {
        if (cartItem.id === shopItem.id) {
          itemFound = true;
          itemCount += itemQuantity;
          return { ...cartItem, quantity: cartItem.quantity + itemQuantity };
        } else {
          return cartItem;
        }
      });

      // the item to add is not in the cart, so init the quantity
      if (!itemFound) {
        itemCount += itemQuantity;
        finalCartItems = [...finalCartItems, { ...shopItem, quantity: itemQuantity }];
      }
      // add item price to the grand total
      total = {
        currency: shopItem.unitPrice.currency,
        value: String(+total.value + itemQuantity * +shopItem.unitPrice.value),
      };
      state.cartItems = finalCartItems;
      state.itemCount = itemCount;
      state.total = total;
      state.loading = false;
    },
    removeCartItem: (state, action) => {
      const { shopItem } = action.payload;

      let finalCartItems = state.cartItems;
      let itemCount = state.itemCount;
      let total = state.total;

      // check if the item to remove has quantity more than one, otherwise don't remove
      if (shopItem.quantity > 1) {
        finalCartItems = finalCartItems.map(cartItem => {
          if (cartItem.id === shopItem.id) {
            itemCount -= 1;
            return { ...cartItem, quantity: cartItem.quantity - 1 };
          } else {
            return cartItem;
          }
        });

        // remove item price to the grand total
        total = {
          ...total,
          value: String(+total.value - +shopItem.unitPrice.value),
        };
      }

      state.cartItems = finalCartItems;
      state.itemCount = itemCount;
      state.total = total;
      state.loading = false;
    },
    clearCartItem: (state, action) => {
      const { shopItem } = action.payload;

      let finalCartItems = state.cartItems;
      let itemCount = state.itemCount;
      let total = state.total;

      // clear item from cart
      finalCartItems = finalCartItems.filter(cartItem => cartItem.id !== shopItem.id);

      // remove item quantity from total quantities
      itemCount -= shopItem.quantity;

      // remove item price to the grand total
      total = {
        ...total,
        value: String(+total.value - shopItem.quantity * +shopItem.unitPrice.value),
      };
      if (total.value < 0) total = { ...total, value: 0 };

      state.cartItems = finalCartItems;
      state.itemCount = itemCount;
      state.total = total;
      state.loading = false;
    },
  },
});

export const { resetCart, addCartItem, removeCartItem, clearCartItem } = cart.actions;
export const cartReducer = cart.reducer;
