import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { fetchAllBlogPosts } from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const blogPostsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.published_at.localeCompare(a.published_at), // sort by descending `published_at`
});

const blogPosts = createSlice({
  name: 'blogPosts',
  initialState,
  reducers: {
    resetBlogPosts: () => initialState,
  },
  extraReducers: {
    [fetchAllBlogPosts.fulfilled]: (state, action) => {
      if (action.payload.entities.blogPosts) {
        blogPostsAdapter.setAll(state, action.payload.entities.blogPosts);
      } else {
        blogPostsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllBlogPosts.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllBlogPosts.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { resetBlogPosts } = blogPosts.actions;
export const blogPostsReducer = blogPosts.reducer;
