import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  addGuardFirstPayment,
  addOnePayment,
  fetchAllEstatesPayments,
  fetchAllPayments,
  fetchPaymentByTaekiaId,
} from './thunks';

// Define redux-toolkit entity adapter
export const paymentsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

const payments = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    resetPayments: () => initialState,
  },
  extraReducers: {
    [fetchAllPayments.fulfilled]: (state, action) => {
      if (action.payload.entities.payments) {
        paymentsAdapter.setAll(state, action.payload.entities.payments);
      } else {
        paymentsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllPayments.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllPayments.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchAllEstatesPayments.fulfilled]: (state, action) => {
      if (action.payload.entities.payments) {
        paymentsAdapter.setAll(state, action.payload.entities.payments);
      } else {
        paymentsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllEstatesPayments.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllEstatesPayments.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchPaymentByTaekiaId.fulfilled]: (state, action) => {
      if (action.payload.entities.payments) {
        paymentsAdapter.upsertMany(state, action.payload.entities.payments);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchPaymentByTaekiaId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchPaymentByTaekiaId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [addOnePayment.fulfilled]: (state, action) => {
      paymentsAdapter.addOne(state, action.payload);
      state.error = false;
      state.loading = false;
    },
    [addOnePayment.pending]: (state, action) => {
      state.loading = true;
    },
    [addOnePayment.rejected]: (state, action) => {
      state.error = action.payload || action.error;
      state.loading = false;
    },
    [addGuardFirstPayment.fulfilled]: (state, action) => {
      paymentsAdapter.addOne(state, action.payload);
      state.error = false;
      state.loading = false;
    },
    [addGuardFirstPayment.pending]: (state, action) => {
      state.loading = true;
    },
    [addGuardFirstPayment.rejected]: (state, action) => {
      state.error = action.payload || action.error;
      state.loading = false;
    },
  },
});

export const { resetPayments } = payments.actions;
export const paymentsReducer = payments.reducer;
