import { mondialRelaysAdapter } from './slice';

const selectSelf = state => state.shop.mondialRelays;

export const {
  selectById: selectMondialRelayById,
  selectIds: selectMondialRelayIds,
  selectEntities: selectMondialRelayEntities,
  selectAll: selectAllMondialRelays,
  selectTotal: selectTotalMondialRelays,
} = mondialRelaysAdapter.getSelectors(selectSelf);
