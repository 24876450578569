import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { ghostApi } from '../../../connectivity';

// Define normalizr entity schemas
const blogPostEntity = new schema.Entity('blogPosts');

// Thunks definitions
export const fetchAllBlogPosts = createAsyncThunk(
  'blogPosts/fetchAllBlogPosts',
  async (params, { getState }) => {
    const data = await ghostApi.get('/posts/', {
      params: { order: 'published_at DESC', limit: 5, filter: 'tag:taekia-guard-app' },
    });
    const normalized = normalize(data.posts, [blogPostEntity]);
    return normalized;
  }
);
