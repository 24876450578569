import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { appointmentsAdapter } from './slice';

const selectSelf = state => state.guard.appointments;

export const {
  selectById: selectAppointmentById,
  selectIds: selectAppointmentIds,
  selectEntities: selectAppointmentEntities,
  selectAll: selectAllAppointments,
  selectTotal: selectTotalAppointments,
} = appointmentsAdapter.getSelectors(selectSelf);

export const selectCurrentAppointment = createDraftSafeSelector(
  selectSelf,
  ({ entities, currentAppointmentId }) => {
    return entities[currentAppointmentId];
  }
);

export const selectCurrentAppointmentId = createDraftSafeSelector(
  selectSelf,
  ({ currentAppointmentId }) => {
    return currentAppointmentId;
  }
);
