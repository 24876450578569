import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { receivedInvitationsAdapter } from './slice';

const selectSelf = state => state.guard.receivedInvitations;

export const {
  selectById: selectReceivedInvitationById,
  selectIds: selectReceivedInvitationIds,
  selectEntities: selectReceivedInvitationEntities,
  selectAll: selectAllReceivedInvitations,
  selectTotal: selectTotalReceivedInvitations,
} = receivedInvitationsAdapter.getSelectors(selectSelf);

export const selectReceivedManagerInvitations = createDraftSafeSelector(
  selectAllReceivedInvitations,
  invitations => {
    return invitations.filter(inv => inv.circle === 0);
  }
);

export const selectReceivedGuardianInvitations = createDraftSafeSelector(
  selectAllReceivedInvitations,
  invitations => {
    return invitations.filter(inv => inv.circle > 0);
  }
);
