/* eslint-disable default-case */
// Retrieve the icon for the associated alarm action
export const getAlarmActionIcon = action => {
  switch (action) {
    case 'call-police':
      return {
        icon: { pack: 'emoji', name: 'policeman-light' },
      };
    case 'decline':
      return {
        icon: { pack: 'emoji', name: 'thumbsdown-light' },
      };
    case 'participate':
      return {
        icon: { pack: 'emoji', name: 'thumbsup-light' },
      };
    case 'intrusion':
      return {
        icon: { pack: 'emoji', name: 'person-running-light' },
      };
    case 'no-intrusion':
      return {
        icon: { pack: 'emoji', name: 'thumbsup-light' },
      };
    case 'mishandling':
      return {
        icon: { pack: 'emoji', name: 'sweat-smile' },
      };
    case 'request-circle-1':
      return {
        icon: {
          pack: 'taekia',
          name: 'circle-1',
          color: 'primary',
        },
      };
    case 'request-circle-2':
      return {
        icon: {
          pack: 'taekia',
          name: 'circle-2',
          color: 'secondary',
        },
      };
  }
};

// Retrieve the icon for the associated event (alarm or sensor type)
export const getEventTypeIcon = event => {
  switch (event) {
    case 'presence':
      return {
        pack: 'taekia',
        name: 'event-motion-presence',
      };
    case 'absence':
      return {
        pack: 'taekia',
        name: 'event-motion-absence',
      };
    case 'open':
      return {
        pack: 'taekia',
        name: 'event-contact-open',
      };
    case 'close':
      return {
        pack: 'taekia',
        name: 'event-contact-close',
      };
    case 'battery':
      return {
        pack: 'eva',
        name: 'battery',
      };
    case 'alarm-on':
      return {
        pack: 'taekia',
        name: 'event-siren-alarm-on',
      };
    case 'alarm-off':
      return {
        pack: 'taekia',
        name: 'event-siren-alarm-off',
      };
    case 'request-circle-1':
      return {
        pack: 'taekia',
        name: 'circle-1',
      };
    case 'request-circle-2':
      return {
        pack: 'taekia',
        name: 'circle-2',
      };
    case 'participate':
      return {
        pack: 'eva',
        name: 'person-add',
      };
    case 'decline':
      return {
        pack: 'eva',
        name: 'person-delete',
      };
    case 'no-intrusion':
      return {
        pack: 'eva',
        name: 'checkmark-circle-2',
      };
    case 'intrusion':
      return {
        pack: 'eva',
        name: 'alert-triangle',
      };
    case 'mishandling':
      return {
        pack: 'eva',
        name: 'close-circle',
      };
    case 'timeout-ongoing':
      return {
        pack: 'eva',
        name: 'clock-outline',
      };
    case 'timeout-intrusion':
      return {
        pack: 'eva',
        name: 'clock-outline',
      };
    case 'call-police':
      return {
        pack: 'eva',
        name: 'bell',
      };
    case 'call-member':
      return {
        pack: 'eva',
        name: 'phone-call',
      };
    case 'message':
      return {
        pack: 'eva',
        name: 'message-square',
      };
    case 'low-to-medium':
      return {
        pack: 'eva',
        name: 'arrow-ios-upward',
      };
    case 'medium-to-high':
      return {
        pack: 'eva',
        name: 'arrowhead-up',
      };
    case 'high-to-medium':
      return {
        pack: 'eva',
        name: 'arrow-ios-downward',
      };
    case 'medium-to-low':
      return {
        pack: 'eva',
        name: 'arrowhead-down',
      };
    case 'suggest-circle-1':
      return {
        pack: 'eva',
        name: 'bell',
      };
    case 'suggest-circle-2':
      return {
        pack: 'eva',
        name: 'bell',
      };
    case 'cancel-auto-request-circle-1':
      return {
        pack: 'eva',
        name: 'bell-off',
      };
    case 'cancel-auto-request-circle-2':
      return {
        pack: 'eva',
        name: 'bell-off',
      };
    default:
      return {
        pack: 'taekia',
        name: 'guard-logo',
      };
  }
};

export const getEstateUsageIcon = usage => {
  switch (usage) {
    case 'personal':
      return {
        icon: { pack: 'taekia', name: 'personal', color: 'gray-dark' },
        iconSelected: { pack: 'taekia', name: 'personal', color: 'primary' },
      };
    case 'professional':
      return {
        icon: { pack: 'taekia', name: 'professional', color: 'gray-dark' },
        iconSelected: { pack: 'taekia', name: 'professional', color: 'primary' },
      };
  }
};

export const getEstateTypeIcon = type => {
  switch (type) {
    case 'appartment':
      return {
        icon: { pack: 'emoji', name: 'department-store-mono' },
        iconSelected: { pack: 'emoji', name: 'department-store' },
      };
    case 'house':
      return {
        icon: { pack: 'emoji', name: 'house-mono' },
        iconSelected: { pack: 'emoji', name: 'house' },
      };
    case 'store':
      return {
        icon: { pack: 'emoji', name: 'convenience-store-mono' },
        iconSelected: { pack: 'emoji', name: 'convenience-store' },
      };
    case 'warehouse':
      return {
        icon: { pack: 'emoji', name: 'package-mono' },
        iconSelected: { pack: 'emoji', name: 'package' },
      };
    default:
      return {
        icon: { pack: 'emoji', name: 'house-mono' },
        iconSelected: { pack: 'emoji', name: 'house' },
      };
  }
};

export const getSensorProximityLevelIcon = proximityLevel => {
  switch (proximityLevel) {
    case '0':
      return {
        icon: { pack: 'emoji', name: 'couch-and-lamp-mono' },
        iconSelected: { pack: 'emoji', name: 'couch-and-lamp' },
      };
    case '1':
      return {
        icon: { pack: 'emoji', name: 'opening-mono' },
        iconSelected: { pack: 'emoji', name: 'opening' },
      };
    case '2':
      return {
        icon: { pack: 'emoji', name: 'deciduous-tree-mono' },
        iconSelected: { pack: 'emoji', name: 'deciduous-tree' },
      };
    default:
      return {
        icon: { pack: 'emoji', name: 'couch-and-lamp' },
        iconSelected: { pack: 'emoji', name: 'couch-and-lamp' },
      };
  }
};

export const getSensorLocationIcon = location => {
  switch (location) {
    default:
      return {
        icon: { pack: 'taekia', name: location },
        iconSelected: { pack: 'taekia', name: location },
      };
  }
};
