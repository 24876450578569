const colors = {
  text: '#0B0B0B',
  'text-inverted': '#FFFFFF',
  background: '#FFFFFF',
  'background-inverted': '#0B0B0B',

  highlight: '#FB9BA4',

  'primary-lightest': '#FB9BA4',
  'primary-lighter': '#F46982',
  'primary-light': '#E94372',
  primary: '#DB0B5B',
  'primary-dark': '#BC085F',
  'primary-darker': '#9D055E',
  'primary-darkest': '#7F0357',

  'secondary-lightest': '#FFDAB9',
  'secondary-lighter': '#FFC096',
  'secondary-light': '#FFA87B',
  secondary: '#FF7F50',
  'secondary-dark': '#DB5A3A',
  'secondary-darker': '#B73A28',
  'secondary-darkest': '#932019',

  'tertiary-lightest': '#9BFBF5',
  'tertiary-lighter': '#68F2F4',
  'tertiary-light': '#42DAE9',
  tertiary: '#0AB8DB',
  'tertiary-dark': '#0790BC',
  'tertiary-darker': '#056C9D',
  'tertiary-darkest': '#034D7F',

  black: '#0B0B0B',
  white: '#FFFFFF',

  'gray-lightest': '#FAF9F9',
  'gray-lighter': '#F4F3F4',
  'gray-light': '#EAE8E9',
  gray: '#D3CFD0',
  'gray-dark': '#A79FA1',
  'gray-darker': '#7B6F72',
  'gray-darkest': '#4F3F44',

  'primary-transparent-light': 'rgba(219, 11, 91, 0.08)',
  'primary-transparent': 'rgba(219, 11, 91, 0.24)',
  'secondary-transparent-light': 'rgba(255, 127, 80, 0.08)',
  'secondary-transparent': 'rgba(255, 127, 80, 0.24)',
  'tertiary-transparent-light': 'rgba(188, 236, 246, 0.08)',
  'tertiary-transparent': 'rgba(188, 236, 246, 0.24)',
  'gray-transparent-light': 'rgba(234, 232, 233, 0.08)',
  'gray-transparent': 'rgba(211, 207, 208, 0.08)',
  'gray-transparent-darkest': 'rgba(79, 63, 68, 0.08)',

  'white-transparent-light': 'rgba(255, 255, 255, 0.08)',
  'white-transparent': 'rgba(255, 255, 255, 0.24)',
  'black-transparent': 'rgba(0, 0, 0, 0.7)',

  success: '#42C42B',
  warning: '#FFD000',
  error: '#F51D40',

  // modes: {
  //   dark: {
  //     text: '#FFFFFF',
  //     'text-inverted': '#0B0B0B',
  //     background: '#0B0B0B',
  //     'background-inverted': '#FFFFFF',
  //     primary: '#DB0B5B',
  //     secondary: '#FF7F50',
  //     tertiary: '#BCECF6',
  //   },
  // },
};

const text = {
  heading: { fontFamily: 'heading', lineHeight: 'heading', fontWeight: 'heading' },
  body: { fontFamily: 'body', fontSize: [18, 20], lineHeight: 'body', fontWeight: 'body' },
  h1: { fontSize: ['h1-mobile', 'h1'], fontWeight: 'extra', lineHeight: 1.1 },
  h2: { fontSize: ['h2-mobile', 'h2'], fontWeight: 'extra', lineHeight: 1.2 },
  h3: { fontSize: ['h3-mobile', 'h3'], fontWeight: 'extra', lineHeight: 1.3 },
  h4: { fontSize: ['h4-mobile', 'h4'], fontWeight: 'bold', lineHeight: 1.4 },
  h5: { fontSize: ['h5-mobile', 'h5'], fontWeight: 'bold', lineHeight: 1.6 },
  h6: { fontSize: ['h6-mobile', 'h6'], fontWeight: 'bold', lineHeight: 1.6 },
  body1: { fontSize: ['body1-mobile', 'body1'], fontWeight: 'regular', lineHeight: 1.4 },
  body2: { fontSize: ['body2-mobile', 'body2'], fontWeight: 'regular', lineHeight: 1.5 },
  body3: { fontSize: ['body3-mobile', 'body3'], fontWeight: 'regular', lineHeight: 1.6 },
  body4: { fontSize: ['body4-mobile', 'body4'], fontWeight: 'regular', lineHeight: 1.6 },
  body5: { fontSize: ['body5-mobile', 'body5'], fontWeight: 'regular', lineHeight: 1.6 },
  body6: { fontSize: ['body6-mobile', 'body6'], fontWeight: 'regular', lineHeight: 1.6 },
  label1: { fontSize: ['label1-mobile', 'label1'], fontWeight: 'bold', lineHeight: 1.3 },
  label2: { fontSize: ['label2-mobile', 'label2'], fontWeight: 'bold', lineHeight: 1.6 },
  label3: { fontSize: ['label3-mobile', 'label3'], fontWeight: 'semi', lineHeight: 1.7 },
};

const buttons = {
  base: {
    cursor: 'pointer',
    borderRadius: 'm',
    fontFamily: 'body',
    transition: 'all .15s ease-in-out',
    whiteSpace: 'nowrap',
    color: 'background',
    bg: 'primary',
    '&:hover': { bg: 'primary-dark' },
    '&:active': { bg: 'primary' },
  },
  inverted: {
    variant: 'buttons.base',
    color: 'primary',
    bg: 'primary-transparent-light',
    '&:hover': { color: 'primary-dark' },
    '&:active': { color: 'primary' },
  },
  variants: {
    primary: {
      variant: 'buttons.base',
    },
    'primary-inverted': {
      variant: 'buttons.inverted',
    },
    secondary: {
      variant: 'buttons.base',
      bg: 'secondary',
      '&:hover': { bg: 'secondary-dark' },
      '&:active': { bg: 'secondary' },
    },
    'secondary-inverted': {
      variant: 'buttons.inverted',
      color: 'secondary',
      bg: 'secondary-transparent-light',
      '&:hover': { color: 'secondary-dark' },
      '&:active': { color: 'secondary' },
    },
    tertiary: {
      variant: 'buttons.base',
      bg: 'tertiary',
      '&:hover': { bg: 'tertiary-dark' },
      '&:active': { bg: 'tertiary' },
    },
    'tertiary-inverted': {
      variant: 'buttons.inverted',
      color: 'tertiary',
      bg: 'tertiary-transparent-light',
      '&:hover': { color: 'tertiary-dark' },
      '&:active': { color: 'tertiary' },
    },
    gray: {
      variant: 'buttons.base',
      bg: 'gray',
      '&:hover': { bg: 'gray-dark' },
      '&:active': { bg: 'gray' },
    },
    'gray-inverted': {
      variant: 'buttons.inverted',
      color: 'gray',
      bg: 'gray-transparent-light',
      '&:hover': { color: 'gray-dark' },
      '&:active': { color: 'gray' },
    },
    disabled: {
      variant: 'buttons.variants.gray',
      '&:hover': {},
      '&:active': {},
    },
  },
  sizes: {
    s: {
      px: 8,
      py: 4,
      ...text.label3,
    },
    m: {
      px: 10,
      py: 6,
      ...text.label2,
    },
    l: {
      px: 16,
      py: 12,
      ...text.label1,
    },
  },
};

const badges = {
  base: {
    // cursor: 'pointer',
    borderRadius: 9999,
    fontFamily: 'body',
    transition: 'all .15s ease-in-out',
    whiteSpace: 'nowrap',
    color: 'background',
    bg: 'primary',
    // '&:hover': { bg: 'primary-darker' },
    // '&:active': { boxShadow: 'none' },
  },
  variants: {
    primary: {
      variant: 'badges.base',
    },
    secondary: {
      variant: 'badges.base',
      bg: 'secondary',
      // '&:hover': { bg: 'secondary-darker' },
    },
    tertiary: {
      variant: 'badges.base',
      bg: 'tertiary',
      // '&:hover': { bg: 'tertiary-darker' },
    },
    success: {
      variant: 'badges.base',
      bg: 'success',
      // '&:hover': { bg: 'success-darker' },
    },
    warning: {
      variant: 'badges.base',
      bg: 'warning',
      // '&:hover': { bg: 'warning-darker' },
    },
    error: {
      variant: 'badges.base',
      bg: 'error',
      // '&:hover': { bg: 'error-darker' },
    },
    gray: {
      variant: 'badges.base',
      bg: 'gray',
      '&:hover': { bg: 'gray-darker' },
    },
  },
  sizes: {
    s: {
      px: 4,
      py: 0,
      ...text.label3,
    },
    m: {
      px: 6,
      py: 2,
      ...text.label2,
    },
    l: {
      px: 8,
      py: 4,
      ...text.label1,
    },
  },
};

const chips = {
  base: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 9999,
    fontFamily: 'body',
    fontSize: 'label2',
    transition: 'all .15s ease-in-out',
    whiteSpace: 'nowrap',
    color: 'background',
    bg: 'primary',
    px: 's',
    // variant: `badges.${variant}`,
  },
  variants: {
    primary: {
      variant: 'chips.base',
    },
    secondary: {
      variant: 'chips.base',
      bg: 'secondary',
    },
    tertiary: {
      variant: 'chips.base',
      bg: 'tertiary',
    },
    black: {
      variant: 'chips.base',
      bg: 'black',
    },
    white: {
      variant: 'chips.base',
      bg: 'white',
    },
    gray: {
      variant: 'chips.base',
      bg: 'gray',
    },
  },
  sizes: {
    s: {
      minWidth: 24,
      minHeight: 24,
      ...text.label3,
    },
    m: {
      minWidth: 34,
      minHeight: 34,
      ...text.label2,
    },
    l: {
      minWidth: 40,
      minHeight: 40,
      ...text.label1,
    },
  },
};

export const theme = {
  colors,
  shadows: {
    dark: '0 0 1rem rgba(0, 0, 0, 0.125)',
    'circle-primary': `0 0 0 0.3rem ${colors['primary-lightest']}`,
    navbar: `0 0.25rem 0.5rem rgba(0, 0, 0, 15%)`,
    'card-primary': `0 0 0.5rem ${colors['gray']}`,
    'card-secondary': `0 0 0.5rem ${colors['gray']}`,
    'card-tertiary': `0 0 0.5rem ${colors['gray']}`,
  },
  fonts: {
    body: '"Baloo 2", "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: '"Baloo 2", "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    monospace: '"Baloo 2", "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  fontSizes: {
    h1: 32,
    'h1-mobile': 28,
    h2: 28,
    'h2-mobile': 25,
    h3: 24,
    'h3-mobile': 22,
    h4: 22,
    'h4-mobile': 21,
    h5: 20,
    'h5-mobile': 20,
    h6: 18,
    'h6-mobile': 18,
    body1: 24,
    'body1-mobile': 21,
    body2: 20,
    'body2-mobile': 18,
    body3: 18,
    'body3-mobile': 17,
    body4: 16,
    'body4-mobile': 16,
    body5: 14,
    'body5-mobile': 14,
    body6: 12,
    'body6-mobile': 12,
    label1: 24,
    'label1-mobile': 21,
    label2: 20,
    'label2-mobile': 18,
    label3: 14,
    'label3-mobile': 14,
  },
  fontWeights: {
    body: 400,
    heading: 700,
    regular: 400,
    medium: 500,
    semi: 600,
    bold: 700,
    extra: 800,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.125,
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em',
  },
  layout: {
    container: {
      width: ['100%', '90%', '90%', '80%'],
      maxWidth: ['100%', '100%', '100%', 1200],
      px: 'l',
    },
  },
  text,
  markdownText: {
    '*': { marginBlock: 0 },
    h1: { variant: 'text.h1' },
    h2: { variant: 'text.h2' },
    h3: { variant: 'text.h3' },
    h4: { variant: 'text.h4' },
    h5: { variant: 'text.h5' },
    h6: { variant: 'text.h6' },
    'ol,ul,p': { variant: 'text.body3', textAlign: 'justify', mb: 'l' },
    'h1,h2': { mb: 'l' },
    'h3,h4,h5,h6': { mb: 'm' },
  },
  badges,
  buttons,
  cards: {
    primary: {
      padding: 'l',
      borderRadius: 'l',
      pageBreakInside: 'avoid',
      breakInside: 'avoid',
      boxShadow: 'card-primary',
      bg: 'background',
    },
    secondary: {
      variant: 'cards.primary',
      boxShadow: 'card-secondary',
    },
    tertiary: {
      variant: 'cards.primary',
      boxShadow: 'card-tertiary',
    },
  },
  chips,
  forms: {
    input: {},
    label: { variant: 'text.label2' },
    checkbox: {
      sizes: {
        s: { width: 24, height: 24 },
        m: { width: 34, height: 34 },
        l: { width: 40, height: 40 },
      },
    },
    radio: {
      sizes: {
        s: { width: 24, height: 24 },
        m: { width: 34, height: 34 },
        l: { width: 40, height: 40 },
      },
    },
  },
  links: {
    nav: {
      fontSize: 20,
      fontWeight: 'extra',
      lineHeight: 1.3,
      px: 'l',
      py: 'm',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    subnav: {
      fontSize: 16,
      fontWeight: 'extra',
      lineHeight: 1.3,
      px: 'm',
      py: 's',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    footer: {
      fontSize: 16,
      fontWeight: 'extra',
      lineHeight: 1.3,
      px: [0, 'm'],
      py: [0, 's'],
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      whiteSpace: 'pre-wrap', // needed when using `\n` in texts
    },
    footer: {
      px: ['l', 80],
      py: ['l', 12],
      color: 'text-inverted',
      bg: 'background-inverted',
    },
    h1: { variant: 'text.h1' },
    h2: { variant: 'text.h2' },
    h3: { variant: 'text.h3' },
    h4: { variant: 'text.h4' },
    h5: { variant: 'text.h5' },
    h6: { variant: 'text.h6' },
    a: { color: 'primary', fontWeight: 'semi', textDecoration: 'none' },
  },
  breakpoints: ['600px', '960px', '1280px', '1920px'],
  space: {
    xs: 2,
    s: 4,
    m: 8,
    l: 16,
    xl: 32,
    xxl: 64,
  },
  radii: {
    xs: 2,
    s: 4,
    m: 8,
    l: 16,
    xl: 32,
    xxl: 64,
  },
  zIndices: {
    '-10': -10,
    0: 0,
    10: 10,
    20: 20,
    30: 30,
    40: 40,
    50: 50,
    60: 60,
    70: 70,
    80: 80,
    90: 90,
  },
};
