import Account from './Account.json';
import AccountPayGuard from './AccountPayGuard.json';
import common from './common.json';
import components from './components.json';
import Landing from './Landing.json';
import screens from './screens.json';
import StartGuard from './StartGuard.json';

export const frFR = {
  ...common,
  ...components,
  ...screens,
  ...StartGuard,
  ...Account,
  ...AccountPayGuard,
  ...Landing,
};
