import { createAsyncThunk } from '@reduxjs/toolkit';

import { addOneOrder } from '../guard';
import { addOneOrderAddress } from '../shop';

export const addOneOrderAndAddresses = createAsyncThunk(
  'orders/addOneOrderAndAddresses',
  async (
    { userId, cartItems, invitationCode, shippingAddress, billingAddress },
    { getState, dispatch, rejectWithValue }
  ) => {
    let shippingAddressId;
    // Create the shipping address
    const shippingAddressResultAction = await dispatch(
      addOneOrderAddress({
        userId,
        address: shippingAddress,
      })
    );
    if (addOneOrderAddress.fulfilled.match(shippingAddressResultAction)) {
      shippingAddressId = shippingAddressResultAction.payload.id;
    } else {
      throw rejectWithValue('Could not create the shippingAddress');
    }

    let billingAddressId;
    if (billingAddress) {
      // Create the billing address
      const billingAddressResultAction = await dispatch(
        addOneOrderAddress({
          userId,
          address: billingAddress,
        })
      );
      if (addOneOrderAddress.fulfilled.match(billingAddressResultAction)) {
        billingAddressId = billingAddressResultAction.payload.id;
      } else {
        throw rejectWithValue('Could not create the billingAddress');
      }
    } else {
      billingAddressId = shippingAddressId;
    }

    // Create the order
    await dispatch(
      addOneOrder({
        cartItems,
        shippingAddressId,
        billingAddressId,
        invitationCode,
        userId,
      })
    );
  }
);
