import { GHOST_API } from 'app/config';
import axios from 'axios';

const ghostApi = axios.create({ baseURL: GHOST_API.URL });

const parseError = err => {
  // Parse the backend response error
  const error = err.response?.data.error;
  // Reject with the formatted error
  return Promise.reject(error);
};

// response parsing interceptor
ghostApi.interceptors.response.use(response => response.data, parseError);

// request parsing interceptor
ghostApi.interceptors.request.use(
  async config => {
    config.headers = {
      'Accept-Version': GHOST_API.VERSION,
    };
    config.params = {
      // Add the Ghost key
      key: GHOST_API.KEY,
      // Spread other params
      ...config.params,
    };
    return config;
  },
  error => Promise.reject(error)
);

export { ghostApi };
