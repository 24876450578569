import { blogPostsAdapter } from './slice';

const selectSelf = state => state.shop.blogPosts;

export const {
  selectById: selectBlogPostById,
  selectIds: selectBlogPostIds,
  selectEntities: selectBlogPostEntities,
  selectAll: selectAllBlogPosts,
  selectTotal: selectTotalBlogPosts,
} = blogPostsAdapter.getSelectors(selectSelf);
