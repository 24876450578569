import { subscriptionsAdapter } from './slice';

const selectSelf = state => state.guard.subscriptions;

export const {
  selectById: selectSubscriptionById,
  selectIds: selectSubscriptionIds,
  selectEntities: selectSubscriptionEntities,
  selectAll: selectAllSubscriptions,
  selectTotal: selectTotalSubscriptions,
} = subscriptionsAdapter.getSelectors(selectSelf);
