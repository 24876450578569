import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { guardApi } from '../../../connectivity';

// Define normalizr entity schemas
export const subscriptionEntity = new schema.Entity('subscriptions');

// Thunks definitions
export const fetchAllSubscriptions = createAsyncThunk(
  'subscriptions/fetchAllSubscriptions',
  async ({ userId }, thunkAPI) => {
    const data = await guardApi.get(`/users/${userId}/subscriptions`);
    const normalized = normalize(data, [subscriptionEntity]);
    return normalized;
  }
);

export const fetchAllEstatesSubscriptions = createAsyncThunk(
  'subscriptions/fetchAllEstatesSubscriptions',
  async ({ userId }, { getState }) => {
    const filter = { order: 'createdAt DESC', include: ['subscription'] };
    const data = await guardApi.get(`/users/${userId}/estates-subscriptions`, {
      params: { filter },
    });
    // Because data are nested, we extract only the attributes of the subscription entity and add links to other entities
    const subscriptions = [];
    for (const eS of data) {
      if (eS.subscription) {
        subscriptions.push({ ...eS.subscription, estateId: eS.estateId });
      }
    }
    const normalized = normalize(subscriptions, [subscriptionEntity]);
    return normalized;
  }
);

export const fetchSubscriptionByEstateId = createAsyncThunk(
  'subscriptions/fetchSubscriptionByEstateId',
  async ({ estateId }, thunkAPI) => {
    const filter = { where: { type: 'guard' } };
    let subscriptions = await guardApi.get(`/estates/${estateId}/subscriptions`, {
      params: { filter },
    });
    subscriptions = subscriptions.map(s => ({ ...s, estateId }));
    const normalized = normalize(subscriptions, [subscriptionEntity]);
    return normalized;
  }
);

// export const createSubscriptionOrPaymentUrl = createAsyncThunk(
//   'subscriptions/createSubscriptionOrPaymentUrl',
//   async ({ estateId }, { rejectWithValue }) => {
//     try {
//       const subscriptionOrPaymentUrl = await guardApi.post(
//         `/estates/${estateId}/subscription/guard`
//       );
//       return subscriptionOrPaymentUrl;
//       /* if (typeof data !== 'string') {
//       // const normalized = normalize(data, [subscriptionEntity]);
//       // return normalized;
//     } else {
//       return subscriptionOrPaymentUrl;
//     } */
//     } catch (e) {
//       throw rejectWithValue(e);
//     }
//   }
// );
