import { shopSectionsAdapter } from './slice';

const selectSelf = state => state.shop.shopSections;

export const {
  selectById: selectShopSectionById,
  selectIds: selectShopSectionIds,
  selectEntities: selectShopSectionEntities,
  selectAll: selectAllShopSections,
  selectTotal: selectTotalShopSections,
} = shopSectionsAdapter.getSelectors(selectSelf);
