import { combineReducers } from 'redux';

import { usersReducer } from '../auth';
import {
  estatesReducer,
  membersReducer,
  ordersReducer,
  paymentsReducer,
  receivedInvitationsReducer,
  subscriptionsReducer,
} from '../guard';
import {
  appointmentsReducer,
  blogPostsReducer,
  cartReducer,
  mondialRelaysReducer,
  orderAddressesReducer,
  shopItemsReducer,
  shopSectionsReducer,
} from '../shop';

export const combinedReducer = combineReducers({
  auth: combineReducers({
    users: usersReducer,
  }),
  guard: combineReducers({
    estates: estatesReducer,
    members: membersReducer,
    orders: ordersReducer,
    payments: paymentsReducer,
    receivedInvitations: receivedInvitationsReducer,
    subscriptions: subscriptionsReducer,
  }),
  shop: combineReducers({
    appointments: appointmentsReducer,
    blogPosts: blogPostsReducer,
    cart: cartReducer,
    mondialRelays: mondialRelaysReducer,
    orderAddresses: orderAddressesReducer,
    shopItems: shopItemsReducer,
    shopSections: shopSectionsReducer,
  }),
});
