import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { shopApi } from '../../../connectivity';

// Define normalizr entity schemas
const orderAddressEntity = new schema.Entity('orderAddresses');

// Thunks definitions
export const fetchAllOrderAddresses = createAsyncThunk(
  'orderAddresses/fetchAllOrderAddresses',
  async ({ userId }, { getState }) => {
    const filter = { order: 'createdAt DESC' };
    const data = await shopApi.get(`/users/${userId}/order-addresses`, { params: { filter } });
    const normalized = normalize(data, [orderAddressEntity]);
    return normalized;
  }
);

export const addOneOrderAddress = createAsyncThunk(
  'orderAddresses/addOneOrderAddress',
  async ({ userId, address }, { getState }) => {
    const data = await shopApi.post(`/users/${userId}/order-addresses`, {
      firstName: address.firstName,
      lastName: address.lastName,
      email: address.email,
      phone: address.phone,
      companyName: address.companyName,
      formatted: address.formatted,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      cityLocality: address.cityLocality,
      stateProvince: address.stateProvince,
      postalCode: address.postalCode,
      country: address.country,
      countryCode: address.countryCode,
      location: address.location,
      mondialRelay: address.mondialRelay,
      userId,
    });
    return data;
  }
);
