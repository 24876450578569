import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { guardApi } from '../../../connectivity';

// Define normalizr entity schemas
const paymentEntity = new schema.Entity('payments');

// Thunks definitions
export const fetchAllPayments = createAsyncThunk(
  'payments/fetchAllPayments',
  async ({ userId }, { getState }) => {
    const filter = {
      order: 'createdAt DESC',
      where: { status: 'paid' },
      include: ['items'],
    };
    const data = await guardApi.get(`/users/${userId}/payments`, { params: { filter } });
    const normalized = normalize(data, [paymentEntity]);
    return normalized;
  }
);

export const fetchAllEstatesPayments = createAsyncThunk(
  'payments/fetchAllEstatesPayments',
  async ({ userId }, { getState }) => {
    const filter = {
      order: 'createdAt DESC',
      include: [
        'estate',
        {
          relation: 'payment',
          scope: { where: { status: 'paid' }, include: [{ relation: 'items' }] },
        },
      ],
    };
    const data = await guardApi.get(`/users/${userId}/estates-payments`, { params: { filter } });
    // Because data are nested, we extract only the attributes of the payment entity and add links to other entities
    const payments = [];
    for (const eP of data) {
      if (eP.payment) {
        payments.push({ ...eP.payment, estateId: eP.estateId });
      }
    }
    const normalized = normalize(payments, [paymentEntity]);
    return normalized;
  }
);

export const fetchPaymentByTaekiaId = createAsyncThunk(
  'payments/fetchPaymentByTaekiaId',
  async ({ userId, taekiaId }, { getState }) => {
    const filter = {
      where: { taekiaId },
      include: ['items'],
    };
    const data = await guardApi.get(`/users/${userId}/payments`, { params: { filter } });
    const normalized = normalize(data, [paymentEntity]);
    return normalized;
  }
);

export const addOnePayment = createAsyncThunk(
  'payments/addOnePayment',
  async ({ estateId, cartItems }, { getState, dispatch, rejectWithValue }) => {
    const data = await guardApi.post(`/estates/${estateId}/payments`, {
      cartItems,
    });
    return data;
  }
);

export const addGuardFirstPayment = createAsyncThunk(
  'estates/addGuardFirstPayment',
  async ({ estateId, orderId }, { getState, dispatch, rejectWithValue }) => {
    const data = await guardApi.post(`/estates/${estateId}/payments/first/guard`, {
      orderId,
    });
    return data;
  }
);
