import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { shopApi } from '../../../connectivity';

// Define normalizr entity schemas
const appointmentEntity = new schema.Entity('appointments');

// Thunks definitions
export const fetchAllAppointments = createAsyncThunk(
  'appointments/fetchAllAppointments',
  async ({ userId }, { getState }) => {
    const filter = { order: 'createdAt DESC' };
    const data = await shopApi.get(`/users/${userId}/appointments`, { params: { filter } });
    const normalized = normalize(data, [appointmentEntity]);
    // console.log(JSON.stringify(normalized, null, 2));
    return normalized;
  }
);

export const fetchAppointmentById = createAsyncThunk(
  'appointments/fetchAppointmentById',
  async ({ userId, appointmentId }, { getState }) => {
    const filter = { where: { id: appointmentId }, order: 'createdAt DESC' };
    const data = await shopApi.get(`/users/${userId}/appointments`, {
      params: { filter },
    });
    const normalized = normalize(data, [appointmentEntity]);
    // console.log(JSON.stringify(normalized, null, 2));
    return normalized;
  }
);

export const addOneAppointment = createAsyncThunk(
  'appointments/addOneAppointment',
  async ({ estateId, appointment }, { getState, dispatch }) => {
    const data = await shopApi.post(`/estates/${estateId}/appointments`, appointment);
    return data;
  }
);

export const updateOneAppointment = createAsyncThunk(
  'appointments/updateOneAppointment',
  async ({ userId, appointment }, { getState }) => {
    const where = JSON.stringify({ id: appointment.id });
    await shopApi.patch(`/users/${userId}/appointments?where=${where}`, appointment);
    return appointment;
  }
);
