import { Link, Box, Flex, Grid, Heading, Icon, Text } from '@taekia/ui-lib-web';
import * as React from 'react';
export default {
  Link,
  Box,
  Flex,
  Grid,
  Heading,
  Icon,
  Text,
  React
};