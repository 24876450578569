// GLOBALS
export const SITE_DOMAIN = 'guard.taekia.tech';

export const APP_VERSION = process.env.NODE_ENV === 'production' ? 'v0.1' : 'dev';

export const API_VERSION = process.env.NODE_ENV === 'production' ? 'v0.1' : 'dev';

// CORE
export const AUTH_API_URL =
  process.env.NODE_ENV === 'production'
    ? `https://api.auth.taekia.tech/${API_VERSION}`
    : 'https://dev.api.auth.taekia.tech';

export const GUARD_API_URL =
  process.env.NODE_ENV === 'production'
    ? `https://api.guard.taekia.tech/${API_VERSION}`
    : 'https://dev.api.guard.taekia.tech';

export const SHOP_API_URL =
  process.env.NODE_ENV === 'production'
    ? `https://api.shop.taekia.tech/${API_VERSION}`
    : 'https://dev.api.shop.taekia.tech';

export const GHOST_API = {
  URL: 'https://blog.taekia.tech/ghost/api/content',
  VERSION: 'v5.2',
  KEY: '5064e00c5680d1019eb0c53701',
};

// SOCIALS
export const TAEKIA_TWITTER = 'https://twitter.com/taekiatech';

export const TAEKIA_FACEBOOK = 'https://www.facebook.com/taekiatech';

export const TAEKIA_INSTAGRAM = 'https://www.instagram.com/taekiatech/';

export const TAEKIA_GUARD_APPLE = 'https://apps.apple.com/fr/app/taekia-guard/id1560821336';

export const TAEKIA_GUARD_GOOGLE =
  'https://play.google.com/store/apps/details?id=tech.taekia.guard';

export const GOOGLE_API_KEY = 'AIzaSyDTd3ehaZtbdomHG8ul-kyW0nmEcB9bZSE';

export const CHATWOOT_URL = 'https://help.taekia.tech';

export const CHATWOOT_TOKEN = 'DxzaWZan26AcJmgxDs61uCaE';

export const PLAUSIBLE_URL = 'https://plausible.taekia.tech';

export const TAEKIA_CONTACT_MAIL = 'contact@taekia.tech';

export const PATH = { START_GUARD: '/start/guard' };

export const USLUG = {
  GUARD_SUBSCRIPTION: 'guard-subscription',
  GUARD_SENSOR_PACK: 'guard-sensor-pack',
  GUARD_SENSOR_PACK_COMMISSIONING: 'guard-sensor-pack-commissioning',
  GUARD_SIREN: 'guard-siren',
  GUARD_SIREN_COMMISSIONING: 'guard-siren-commissioning',
};
