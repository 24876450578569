import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { pureShopApi } from '../../../connectivity';

// Define normalizr entity schemas
const shopItemEntity = new schema.Entity('shopItems');

// Thunks definitions
export const fetchAllShopItems = createAsyncThunk(
  'shopItems/fetchAllShopItems',
  async ({ language }, { getState }) => {
    const filter = { order: 'unitPrice.value ASC', where: { language } };
    const data = await pureShopApi.get(`/shop-items`, { params: { filter } });
    const normalized = normalize(data, [shopItemEntity]);
    return normalized;
  }
);

export const fetchShopItemBySlug = createAsyncThunk(
  'shopItems/fetchShopItemBySlug',
  async ({ slug, language }, { getState }) => {
    const filter = { where: { slug /* language */ } };
    const data = await pureShopApi.get(`/shop-items`, { params: { filter } });
    const normalized = normalize(data, [shopItemEntity]);
    return normalized;
  }
);
