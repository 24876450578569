/**
 * Finds whether the next billing date must be in the current or next month
 * Depending on the billing day that has been set
 * @returns the next billing date in YYYY-MM-DD format
 */
export const getBillingStartDate = shopItem => {
  if (!shopItem.subscription) {
    throw new Error('Shop item is not a subscription');
  }
  const subscription = shopItem.subscription;
  const billingStartDate = new Date();
  const today = new Date();

  // Bill current month
  billingStartDate.setUTCDate(subscription.billingDay);

  // Bill next month if billing day is already passed
  if (today.getUTCDate() >= subscription.billingDay) {
    billingStartDate.setUTCMonth(billingStartDate.getUTCMonth() + 1);
  }

  return billingStartDate;
};

export const getProrata = shopItem => {
  if (!shopItem.subscription) {
    throw new Error('Shop item is not a subscription');
  }
  const subscription = shopItem.subscription;
  const today = new Date();
  const daysInCurrentMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate(); // number of days in the current month

  // If subscription billing date is today, the prorata is full subscription amount
  if (today.getUTCDate() === subscription.billingDay) {
    return {
      amount: shopItem.unitPrice,
      period: daysInCurrentMonth,
    };
  }

  const billingStartDate = getBillingStartDate(shopItem);
  let period = billingStartDate.getTime() - today.getTime(); // in milliseconds
  period = Math.ceil(period / (1000 * 60 * 60 * 24)); // in days

  // Compute prorata amount value
  const prorataAmountValue = ((+shopItem.unitPrice.value / daysInCurrentMonth) * period).toFixed(2);

  return {
    amount: { currency: 'EUR', value: prorataAmountValue },
    period,
  };
};
