/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { CHATWOOT_TOKEN, CHATWOOT_URL } from 'app/config';
import React from 'react';
export { default as wrapRootElement } from './gatsby-wrapper';

// Load Chatwoot chatting and Plausible services on client first visit
// Called when the initial (but not subsequent) render of Gatsby App is done on the client.

export const onInitialClientRender = () => {
  return (
    <script>
      {(function (d, t) {
        var g = d.createElement(t),
          s = d.getElementsByTagName(t)[0];
        g.src = `${CHATWOOT_URL}/packs/js/sdk.js`;
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g, s);
        g.onload = function () {
          window.chatwootSDK.run({
            websiteToken: CHATWOOT_TOKEN,
            baseUrl: CHATWOOT_URL,
          });
        };
      })(document, 'script')}
    </script>
  );
};

/* export const onServiceWorkerUpdateReady = async args => {
  const permissionResponse = await Notification.requestPermission();
  if (permissionResponse === 'granted') {
    await args.serviceWorker.showNotification('Website update', {
      body: 'Our website just got a little bit better. We reloaded the site with the update to ensure a smooth experience for you.',
    });
  }
  window.location.reload(true);
}; */
