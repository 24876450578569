import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { selectAllEstates, selectAllSubscriptions } from '../guard';

// TODO: we should create dedicated slices for estates context content to not mix with users context
// estates-orders, estates-payments, estates-subscriptions

export const selectAllSubscriptionsIncludeEstate = createDraftSafeSelector(
  selectAllSubscriptions,
  selectAllEstates,
  (allSubscriptions, allEstates) => {
    return allSubscriptions.map(s => {
      const estate = allEstates.find(e => e.id === s.estateId);
      return { ...s, estate };
    });
  }
);

export const selectSubscriptionByEstateId = estateId =>
  createDraftSafeSelector(selectAllSubscriptions, allSubscriptions => {
    return allSubscriptions.find(s => s.estateId === estateId);
  });
