import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { pureShopApi } from '../../../connectivity';

// Define normalizr entity schemas
const shopSectionEntity = new schema.Entity('shopSections');

// Thunks definitions
export const fetchAllShopSections = createAsyncThunk(
  'shopSections/fetchAllShopSections',
  async ({ language }, { getState }) => {
    const filter = { order: 'createdAt DESC', where: { language } };
    const data = await pureShopApi.get(`/shop-sections`, { params: { filter } });
    const normalized = normalize(data, [shopSectionEntity]);
    return normalized;
  }
);
