import { createSlice } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import {
  fetchUser,
  requestLogin,
  requestLogout,
  requestRefreshToken,
  requestSignup,
  requestVerificationCode,
  updateUser,
  updateUserSession,
  updateUserSessionNotificationToken,
} from './thunks';

const initialState = {
  ids: [],
  entities: {},
  user: null,
  accessToken: null,
  refreshToken: null,
  sessionId: null,
  decoded: null,
  phone: null,
  chatwootCookie: null,
  loading: false,
  error: false,
};

const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: () => initialState,
    setUserAccessToken: (state, { payload: { accessToken } }) => {
      state.accessToken = accessToken;
      state.decoded = jwt_decode(state.accessToken);
    },
    setUserChatwootCookie: (state, action) => {
      state.chatwootCookie = action.payload;
    },
  },
  extraReducers: {
    [fetchUser.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.error = false;
      state.loading = false;
    },
    [fetchUser.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchUser.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.error = false;
      state.loading = false;
    },
    [updateUser.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUser.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [updateUserSessionNotificationToken.fulfilled]: (state, action) => {
      state.error = false;
      state.loading = false;
    },
    [updateUserSessionNotificationToken.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUserSessionNotificationToken.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [updateUserSession.fulfilled]: (state, action) => {
      state.error = false;
      state.loading = false;
    },
    [updateUserSession.pending]: (state, action) => {
      state.loading = true;
    },
    [updateUserSession.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [requestVerificationCode.fulfilled]: (state, action) => {
      state.phone = action.payload;
      state.error = false;
      state.loading = false;
    },
    [requestVerificationCode.pending]: (state, action) => {
      state.loading = true;
    },
    [requestVerificationCode.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [requestSignup.fulfilled]: (state, action) => {
      state.error = false;
      state.loading = false;
    },
    [requestSignup.pending]: (state, action) => {
      state.loading = true;
    },
    [requestSignup.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [requestLogin.fulfilled]: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.sessionId = action.payload.sessionId;
      state.decoded = jwt_decode(state.accessToken);
      state.error = false;
      state.loading = false;
    },
    [requestLogin.pending]: (state, action) => {
      state.loading = true;
    },
    [requestLogin.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [requestRefreshToken.fulfilled]: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.sessionId = action.payload.sessionId;
      state.decoded = jwt_decode(state.accessToken);
      state.error = false;
      state.loading = false;
    },
    [requestRefreshToken.pending]: (state, action) => {
      state.loading = true;
    },
    [requestRefreshToken.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [requestLogout.fulfilled]: (state, action) => {
      state.error = false;
      state.loading = false;
    },
    [requestLogout.pending]: (state, action) => {
      state.loading = true;
    },
    [requestLogout.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { resetUsers, setUserAccessToken, setUserChatwootCookie } = users.actions;
export const usersReducer = users.reducer;
