import { unwrapResult } from '@reduxjs/toolkit';

let store;
let requestRefreshToken;
let cleanGlobalState;

export const injectRedux = (_store, _requestRefreshToken, _cleanGlobalState) => {
  store = _store;
  requestRefreshToken = _requestRefreshToken;
  cleanGlobalState = _cleanGlobalState;
};

export const getTaekiaApiHeaders = async () => {
  try {
    const state = await store.getState();
    let { accessToken, decoded } = state.auth.users;
    if (!accessToken || !decoded) {
      return {};
    }
    // Check if the access token is expired
    if (decoded.exp <= Date.now() / 1000) {
      const resultAction = await store.dispatch(requestRefreshToken());
      if (requestRefreshToken.fulfilled.match(resultAction)) {
        accessToken = unwrapResult(resultAction).accessToken;
      } else {
        // If thunk `requestRefreshToken` fails, then clean the global redux state
        store.dispatch(cleanGlobalState());
      }
    }
    return { Authorization: `Bearer ${accessToken}` };
  } catch (error) {
    return {};
  }
};
