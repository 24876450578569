import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { ordersAdapter } from './slice';

const selectSelf = state => state.guard.orders;

export const {
  selectById: selectOrderById,
  selectIds: selectOrderIds,
  selectEntities: selectOrderEntities,
  selectAll: selectAllOrders,
  selectTotal: selectTotalOrders,
} = ordersAdapter.getSelectors(selectSelf);

export const selectOrderByTaekiaId = taekiaId =>
  createDraftSafeSelector(selectAllOrders, orders => {
    return orders.find(o => o.taekiaId === taekiaId);
  });
