import { createDraftSafeSelector } from '@reduxjs/toolkit';

const selectSelf = state => state.auth.users;

export const selectUserPhone = createDraftSafeSelector(selectSelf, ({ phone }) => phone);

export const selectUser = createDraftSafeSelector(selectSelf, ({ user }) => user);

export const selectUserId = createDraftSafeSelector(selectUser, user => user && user.id);

export const selectUserChatwootCookie = createDraftSafeSelector(
  selectSelf,
  ({ chatwootCookie }) => chatwootCookie
);
