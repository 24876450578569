import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  addOneAppointment,
  fetchAllAppointments,
  fetchAppointmentById,
  updateOneAppointment,
} from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const appointmentsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const appointments = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    resetAppointments: () => initialState,
  },
  extraReducers: {
    [fetchAllAppointments.fulfilled]: (state, action) => {
      if (action.payload.entities.appointments) {
        appointmentsAdapter.setAll(state, action.payload.entities.appointments);
      } else {
        appointmentsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllAppointments.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllAppointments.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchAppointmentById.fulfilled]: (state, action) => {
      if (action.payload.entities.appointments) {
        appointmentsAdapter.upsertMany(state, action.payload.entities.appointments);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAppointmentById.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAppointmentById.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [addOneAppointment.fulfilled]: (state, action) => {
      appointmentsAdapter.addOne(state, action.payload);
      state.error = false;
      state.loading = false;
    },
    [addOneAppointment.pending]: (state, action) => {
      state.loading = true;
    },
    [addOneAppointment.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [updateOneAppointment.fulfilled]: (state, action) => {
      appointmentsAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
      state.error = false;
      state.loading = false;
    },
    [updateOneAppointment.pending]: (state, action) => {
      state.loading = true;
    },
    [updateOneAppointment.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { resetAppointments } = appointments.actions;
export const appointmentsReducer = appointments.reducer;
