import { createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { resetUsers } from '../auth';
import {
  resetEstates,
  resetMembers,
  resetOrders,
  resetPayments,
  resetSubscriptions,
} from '../guard';
import {
  resetBlogPosts,
  resetCart,
  resetMondialRelays,
  resetOrderAddresses,
  resetShopItems,
  resetShopSections,
} from '../shop';

export const cleanGlobalState = createAsyncThunk(
  'global/cleanGlobalState',
  async (params, { dispatch }) => {
    batch(() => {
      // Reset all sensible reducers
      dispatch(resetBlogPosts());
      dispatch(resetCart());
      dispatch(resetEstates());
      dispatch(resetMembers());
      dispatch(resetMondialRelays());
      dispatch(resetOrderAddresses());
      dispatch(resetOrders());
      dispatch(resetPayments());
      dispatch(resetShopItems());
      dispatch(resetShopSections());
      dispatch(resetSubscriptions());
      dispatch(resetUsers());
    });
  }
);
