import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { guardApi } from '../../../connectivity';

// Define normalizr entity schemas
const orderEntity = new schema.Entity('orders');

// Thunks definitions
export const fetchAllOrders = createAsyncThunk(
  'orders/fetchAllOrders',
  async ({ userId }, { getState }) => {
    const filter = {
      order: 'createdAt DESC',
      include: ['items', 'shippingAddress', 'billingAddress'],
    };
    const data = await guardApi.get(`/users/${userId}/orders`, { params: { filter } });
    const normalized = normalize(data, [orderEntity]);
    return normalized;
  }
);

export const fetchAllEstatesOrders = createAsyncThunk(
  'orders/fetchAllEstatesOrders',
  async ({ userId }, { getState }) => {
    const filter = {
      order: 'createdAt DESC',
      include: [{ relation: 'order', scope: { include: ['items'] } }],
    };
    const data = await guardApi.get(`/users/${userId}/estates-orders`, { params: { filter } });
    // Because data are nested, we extract only the attributes of the order entity and add links to other entities
    const orders = [];
    for (const eO of data) {
      if (eO.order) {
        orders.push({ ...eO.order, estateId: eO.estateId });
      }
    }
    const normalized = normalize(orders, [orderEntity]);
    return normalized;
  }
);

export const fetchOrderByTaekiaId = createAsyncThunk(
  'orders/fetchOrderByTaekiaId',
  async ({ userId, taekiaId }, { getState }) => {
    const filter = {
      where: { taekiaId },
      include: ['items', 'shippingAddress', 'billingAddress'],
    };
    const data = await guardApi.get(`/users/${userId}/orders`, { params: { filter } });
    const normalized = normalize(data, [orderEntity]);
    return normalized;
  }
);

export const fetchOrderByEstateId = createAsyncThunk(
  'orders/fetchOrderByEstateId',
  async ({ estateId }, thunkAPI) => {
    let orders = await guardApi.get(`/estates/${estateId}/orders`);
    orders = orders.map(o => ({ ...o, estateId }));
    const normalized = normalize(orders, [orderEntity]);
    return normalized;
  }
);

export const addOneOrder = createAsyncThunk(
  'orders/addOneOrder',
  async (
    { userId, estateId, cartItems, invitationCode, shippingAddressId, billingAddressId },
    { getState, dispatch }
  ) => {
    let data;
    if (estateId) {
      data = await guardApi.post(`/estates/${estateId}/orders`, {
        cartItems,
        invitationCode,
        userId,
      });
    } else {
      data = await guardApi.post(`/users/${userId}/orders`, {
        cartItems,
        shippingAddressId,
        billingAddressId,
        invitationCode,
        userId,
      });
    }
    return data;
  }
);

export const addGuard = createAsyncThunk(
  'users/addGuard',
  async ({ userId, estate, appointment, order }, { dispatch, getState, rejectWithValue }) => {
    try {
      const data = await guardApi.post(`/users/${userId}/guard`, {
        estate,
        appointment,
        order,
      });
      return data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);
